import React from "react";
import './Info.css'
import { Navigate, Route, Routes } from "react-router-dom";
import List from "./List";

import PureFetchHtml from "./Pages/PureFetchHtml/PureFetchHtml";

export class Topic {
    public static Important = new Topic("Wichtig")
    public static Politics = new Topic("Politik")
    public static Economy = new Topic("Wirtschaft")
    public static Society = new Topic("Gesellschaft")
    public static Technology = new Topic("Technik")
    public static Communication = new Topic("Kommunikation")

    public static Values = [Topic.Important, Topic.Politics, Topic.Economy, Topic.Society, Topic.Technology, Topic.Communication]
    
    constructor(public displayName: string) { }
}

export interface Page {
    path: string,
    title: string,
    element: React.ReactElement,
    dropdownText: string,
    topics?: Topic[]
}

// Define the pages with unique dropdown text
const pages: Page[] = [
    { path: "politics/verwaltungsordnung", title: "Verwaltungsordnung", element: <PureFetchHtml url='/articles/politics/verwaltungsordnung.html' />, dropdownText: "Der Aufbau und die Funktionsweise des Staates", topics: [Topic.Politics]},
    { path: "politics/geschäftsordnungDesParlaments", title: "Geschäftsördnung des Parlaments", element: <PureFetchHtml url='/articles/politics/geschäftsordnungDesParlaments.html' />, dropdownText: "Die Geschäftsordnung des Parlaments", topics: [Topic.Politics]},
    { path: "politics/partyFounding", title: "Parteigründung", element: <PureFetchHtml url='/articles/politics/partyFounding.html' />, dropdownText: "Alles was ihr wissen müsst, um eure eigene Partei zu gründen", topics: [Topic.Politics]},
    { path: "politics/constitution", title: "Verfassung", element: <PureFetchHtml url='/articles/politics/constitution.html' />, dropdownText: "Die Verfassung von Klaustopia", topics: [Topic.Politics]},
    { path: "politics/intro", title: "Die Politik in unserem Staat", element: <PureFetchHtml url='/articles/politics/intro.html' />, dropdownText: "Eine Einführung in den politischen Aufbau unseres Staates", topics: [Topic.Politics]},
    { path: "economy/intro", title: "Die Wirtschaft in unserem Staat", element: <PureFetchHtml url='/articles/economy/intro.html' />, dropdownText: "Eine Einführung in den wirtschaftlichen Aufbau unseres Staates", topics: [Topic.Economy]},
    { path: "society/intro", title: "Die Gesellschaft in unserem Staat", element: <PureFetchHtml url='/articles/society/intro.html' />, dropdownText: "Eine Einführung in den gesellschaftlichen Aufbau unseres Staates", topics: [Topic.Society]}
];

export default class Info extends React.Component {
    render() {
        return (
        <div className="Info">
            <Routes>
                <Route path='/' element={<List pages={pages}/>} /> 
                {
                    // Mapping each page object to a Route component
                    pages.map(page =>
                        <Route path={page.path} element={page.element} key={page.title}/>
                    )
                }
                <Route path="politics/constitutionDraft" element={<Navigate to="/info/politics/constitution" />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </div>)
    }
}
