export const BACKEND_URL = "https://klausmachtstaat.de/api/";
export const BACKEND_LOGIN = BACKEND_URL + "login";
export const BACKEND_LOGOUT = BACKEND_URL + "logout";
export const BACKEND_PROFILE = BACKEND_URL + "profile";
export const BACKEND_LIST_USERNAMES = BACKEND_URL + "listUsernames";
export const BACKEND_GET_VOTES = BACKEND_URL + "getVotes";
export const BACKEND_GET_OPTIONS = BACKEND_URL + "getOptions";
export const BACKEND_SET_VOTE = BACKEND_URL + "setVote";
export const BACKEND_LIST_TRANSACTIONS = BACKEND_URL + "listTransactions";
export const BACKEND_LIST_TRANSACTION_REQUESTS =
  BACKEND_URL + "listTransactionRequests";
export const BACKEND_ADD_TRANSACTION = BACKEND_URL + "addTransaction";
export const BACKEND_ADD_TRANSACTION_REQUEST =
  BACKEND_URL + "addTransactionRequest";
export const BACKEND_APPROVE_TRANSACTION_REQUEST =
  BACKEND_URL + "approveTransactionRequest";
export const BACKEND_DENY_TRANSACTION_REQUEST =
  BACKEND_URL + "denyTransactionRequest";
